<template>
  <transition :name="params.transition || 'fade'"
              appear>
    <div class="interesting-fact">
      <div class="interesting-fact__wrapper">
        <Row :justify="$laptop ? 'between' : ''"
             :wrap="$mobile">
          <Column class="interesting-fact__image-column">
            <div class="interesting-fact__image-holder">
              <img v-if="params.fact.images"
                   :src="params.fact.images[0]"
                   class="interesting-fact__image"
                   alt=""/>
            </div>
          </Column>
          <Column class="interesting-fact__info">
            <p class="interesting-fact__title">{{params.fact.title}}</p>
            <p class="interesting-fact__description">{{params.fact.description}}</p>
          </Column>
        </Row>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'InterestingFact',
  props: {
    params: {
      type: Object,
      required: true,
    },
  },
  computed: {
    description() {
      let string = this.params.fact.description;
      if (string.length > 220) {
        string = string.slice(0, 220);
        string = `${string}...`;
      }
      return string;
    },
  },
};
</script>
